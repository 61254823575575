
import useChart from '@/composables/use-chart';
import '@/assets/chart.css';
import '@/assets/search.css';

export default {
  name: 'Chart',
  async mounted() {
    this.useChart();
  },
  methods: {
    useChart,
  },
};
