import Vue from 'vue';
import Toasted from 'vue-toasted';
import App from './App.vue';
import router from './router';
import config from '@/config/config';

Vue.config.productionTip = false;

Vue.prototype.config = config;

Vue.use(Toasted);

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');
