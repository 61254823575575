import { reactive } from 'vue';

const auth = reactive({
  idToken: '',
  email: undefined,
  lastName: undefined,
  firstName: undefined,
  fullName: undefined,
});

export default auth;
