
import auth from '@/stores/auth';

export default {
  name: 'app',
  computed: {
    hasLogin() {
      return !!auth.email;
    },
  },
};
