import config from '@/config/config';
import auth from '@/stores/auth';

const getHeaders = () => ({
  'Content-Type': 'application/json',
  /**
   * API authentication using id_token from Google Identity Service instead of access_token
   * access_token is more secure than id_token due to not in the form of JWT which could reveal its content easily by JWT decoder
   * however access_token will require additional request with Google Identity APIs to verify the user access
   * hence id_token is more straight forward at this stage to be validate by API without additional request with Google
   */
  Authorization: `bearer ${auth.idToken}`,
});

export const getCategories = () =>
  fetch(`${config.apiUrl}/categories`, {
    headers: getHeaders(),
  }).then((res) => res.json());

export const getUsers = () =>
  fetch(`${config.apiUrl}/users`, {
    headers: getHeaders(),
  }).then((res) => res.json());

export const getUserData = (email: string) =>
  fetch(`${config.apiUrl}/users/${email}`, {
    headers: getHeaders(),
  });

export const postUserData = (email: string, body: any) =>
  fetch(`${config.apiUrl}/users/${email}`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(body),
  }).then((res) => res.json());
