import * as d3 from 'd3';
import utils from '@/helpers/chart-utils';

const margin = {
    top: 10, right: 10, bottom: 10, left: 10
};

const numLevelsToShow = 1;
const moveThroughAllLevels = true;

const color = d3.scaleOrdinal().range(d3.quantize(d3.interpolateRainbow, 12));

const SunBurst = function () {
    const self = {};
    let sunburstElement, sunburstData, svg, sunburstContainer, currentNode;
    let originalData;
    let radius = 150;
    let segments = [], labels = [], center, upLabel;
    let selectedSegmentListener;
    let colorFull;

    function autoBox() {
        const {x, y, width, height} = this.getBBox();
        return [x, y, width + margin.right + margin.left, height + margin.top + margin.bottom];
    }

    function prepareData(data) {
        const root = d3.hierarchy(data)
            .sum(d => {
                return d.size
            })
            .eachAfter(function(node) {
                var sum = node.data.score || 0,
                    children = node.children,
                    i = children && children.length;
                while (--i >= 0) sum += children[i].score;
                node.score = sum;
            })
            .sort((a, b) => b.value - a.value);
        const partitoned = d3.partition()
            .size([2 * Math.PI, root.height + 1])
            (root);
        partitoned.each(d => d.current = d);
        return partitoned;
    }

    function plotSegments () {
        return sunburstContainer.append('g')
            .attr("fill-opacity", 0.8)
            .selectAll('path')
            .data(sunburstData.descendants().slice(1))
            .enter().append("path")
            .attr("fill", d => {
                while (d.depth > 2) d = d.parent;
                return colorFull(d.data.name);
            }) // return getColor(d.data.name, data); })
            // .attr("fill", d => colorFull(d.data.name))
            .style("display", d => isArcVisible(d.current) ? null : "none")
            .attr("fill-opacity", d => isArcVisible(d.current) ? (d.children ? 0.8 : 0.6) : 0)
            .attr("d", d => createArc(d.current));
    }

    function isArcVisible(d) {
        return d.y1 <= (numLevelsToShow + 1) && d.y0 >= 1 && d.x1 > d.x0;
    }

    function createArc(d) {
        return d3.arc()
            .startAngle(d => d.x0)
            .endAngle(d => d.x1)
            .padAngle(d => Math.min((d.x1 - d.x0) / 2, 0.005))
            .padRadius(radius * 1.5)
            .innerRadius(d => d.y0 * radius / 2)
            .outerRadius(d => Math.max(d.y0 * radius, d.y1 * radius - 1))(d)
    }

    function plotLabels() {
        return sunburstContainer.append("g")
            .attr("pointer-events", "none")
            .attr("text-anchor", "middle")
            .style("user-select", "none")
            .selectAll("text")
            .data(sunburstData.descendants().slice(1))
            .enter().append("text")
            .attr("dy", "0.35em")
            .attr("fill-opacity", d => +isLabelVisible(d.current))
            .attr("transform", d => labelTransform(d.current, radius))
            .text(d =>  {
                return d.data.name + " (" + d.score + ")"
            })
            .call(utils.buggyWrap, radius)
            .style("display", d => isLabelVisible(d.current) ? null : "none");
    }

    function isLabelVisible(d) {
        return d.y1 <= (numLevelsToShow + 1) && d.y0 >= 1 && (d.y1 - d.y0) * (d.x1 - d.x0) > 0.03;
    }

    function labelTransform(d, radius) {
        const x = (d.x0 + d.x1) / 2 * 179.5 / Math.PI;
        const y = (d.y0 + d.y1) / 2 * radius / 1.2;
        return `rotate(${x - 90}) translate(${y},0) rotate(${x < 180 ? 0 : 180})`;
    }

    function plotCenter() {
        const g = sunburstContainer.append('g');

        const center = g.append("circle");
        center
            .datum(sunburstData)
            .attr("r", radius / 2)
            .attr("fill", "none");

        return center;
    }

    function plotUpLabel() {
        const g = sunburstContainer.append("g");
        g.attr("text-anchor", "middle");
        g.selectAll('.up-label').remove();
        return g;
    }

    function updateUpLabel(node) {
        let label = upLabel.selectAll('.up-label').data(node);

        label.exit()
            // .transition().duration(750).style("opacity", 0)
            .remove();

        let labelEnter = label.enter();

        let text = labelEnter
            .append("text")
            .attr('class', 'up-label')
            .attr('x', 0)
            .attr('y', 0)
            .attr('dy', '0.25em')
            .merge(label)
            .transition().duration(325).style("opacity", 0)
            .transition().duration(325).style("opacity", 1)
            .text(d => {
                return d.children && d !== sunburstData ? `${d.data.name}` : '';
            });

    }

    function setCurrentNode(node) {
        currentNode = node;
    }

    function segmentClick(segmentData) {
        selectedSegmentListener(segmentData);
    }

    function transitionSunburstToNode(targetNode) {
        center.datum(targetNode.parent || sunburstData);
        center.style("cursor", targetNode.parent ? "pointer" : null);
        center.selectAll('.up-title').remove();
        if (targetNode.parent) {
            center.append("title")
                .attr("class", "up-title")
                .text("Click to go up");
        }

        updateUpLabel([targetNode]);

        sunburstData.each(d => d.target = {
            x0: Math.max(0, Math.min(1, (d.x0 - targetNode.x0) / (targetNode.x1 - targetNode.x0))) * 2 * Math.PI,
            x1: Math.max(0, Math.min(1, (d.x1 - targetNode.x0) / (targetNode.x1 - targetNode.x0))) * 2 * Math.PI,
            y0: Math.max(0, d.y0 - targetNode.depth),
            y1: Math.max(0, d.y1 - targetNode.depth)
        });

        const t = sunburstContainer.transition().duration(750);

        // Transition the data on all arcs, even the ones that aren’t visible,
        // so that if this transition is interrupted, entering arcs will start
        // the next transition from the desired position.
        segments.transition(t)
            .tween("data", d => {
                const i = d3.interpolate(d.current, d.target);
                return t => d.current = i(t);
            })
            .filter(function(d) {
                return d === currentNode || +this.getAttribute("fill-opacity") || isArcVisible(d.target);
            })
            .attr("fill-opacity", d => {
                if (!d.children && d === currentNode) {
                    return 0.4;
                }
                return isArcVisible(d.target) ? (d.children ? 0.8 : 0.6) : 0
            })
            .attrTween("d", d => () => createArc(d.current, radius))
            .on("start", function(thing){
                if (!thing.children && thing === currentNode) {
                    d3.select(this).style("display", null);
                } else if (isArcVisible(thing.target)) {
                    d3.select(this).style("display", null);
                }
            })
            .on("end", function (thing) {
                if (!thing.children && thing === currentNode) {
                    d3.select(this).style("display", null);
                } else if (!isArcVisible(thing.target)) {
                    d3.select(this).style("display", "none");
                }
            });

        labels.filter(function(d) {
            return d === currentNode || +this.getAttribute("fill-opacity") || isLabelVisible(d.target);
        }).transition(t)
            .attr("fill-opacity", d => {
                if (!d.children && d === currentNode) {
                    return 1;
                }
                return +isLabelVisible(d.target);
            })
            .attrTween("transform", d => () => labelTransform(d.current, radius))
            .on("start", function(thing){
                if (!thing.children && thing === currentNode) {
                    d3.select(this).style("display", null);
                } else if (isLabelVisible(thing.target)) {
                    d3.select(this).style("display", null);
                }
            })
            .on("end", function (thing) {
                if (!thing.children && thing === currentNode) {
                    d3.select(this).style("display", null);
                } else if (!isLabelVisible(thing.target)) {
                    d3.select(this).style("display", "none");
                }
            });
    }

    function animateMove(id) {
        const node = utils.findLeafNodeOfPartitionedDataById(sunburstData, id);
        let pathNodes = currentNode.path(node);
        pathNodes.shift();
        let i = 0, iterations = pathNodes.length;
        function move() {
            let node = pathNodes.shift();
            currentNode = node;
            transitionSunburstToNode(node);
            i++;
            if (i < iterations) {
                setTimeout(move, 700);
            }
        }
        if (iterations) {
            move();
        }
    }

    function normalMove(id) {
        const node = utils.findLeafNodeOfPartitionedDataById(sunburstData, id);
        if (node) {
            currentNode = node;
            transitionSunburstToNode(node);
        }
    }

    self.init = function(rootD3Element, data) {
        sunburstElement = rootD3Element;
        originalData = JSON.parse(JSON.stringify(data));
        colorFull = utils.createColorFunction(originalData);
        sunburstData = prepareData(data);
        setCurrentNode(sunburstData);
        return self;
    };

    self.plot = function() {
        svg = sunburstElement.append('svg')
            .style("max-height", "100%")
            .style("width", "auto");

        sunburstContainer = svg
            .append("g");
        segments = plotSegments();
        labels = plotLabels();
        upLabel = plotUpLabel();
        center = plotCenter();
        updateUpLabel([sunburstData]);
        svg.attr("viewBox", autoBox);

    };

    self.enableInteraction = function(isEnabled, listener) {
        if (isEnabled) {
            selectedSegmentListener = listener;
            segments
                .style("cursor", "pointer")
                .on("click", segmentClick);

            center
                .attr("pointer-events", "all")
                .on("click", segmentClick);
        } else {
            selectedSegmentListener = null;
            segments
                .filter(d => d.children)
                .style("cursor", "default")
                .on("click", null);

            center
                .attr("pointer-events", "all")
                .on("click", null);
        }
    };

    self.hide = function() {
        sunburstElement.style('display', 'none');
    };

    self.show = function() {
        sunburstElement.style('display', null);
    };

    self.moveSunburstToCategoryById = function(id) {
        if (moveThroughAllLevels) {
            animateMove(id);
        } else {
            normalMove(id);
        }
    };

    return self;
};

export default SunBurst;
