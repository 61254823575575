import * as d3 from 'd3';
import { watch } from 'vue';

import SunBurst from './graphing/sunburst';
import FullViewSunBurst from './graphing/fullViewSunBurst';
import Breadcrumb from './graphing/breadcrumb';
import useSearch from './use-search';
import UserList from './userList';
import UserSkillsTable from './userSkillsTable';

import chart from '@/stores/chart';
import utils from '@/helpers/chart-utils';
import { getCategories, getUsers } from '@/services/DataService';

const actions = {
  updateCategory: (payload) => {
    if (payload) {
      utils.mergeParams({
        category: payload,
      });
    } else {
      utils.removeParam('category');
    }
    chart.category = payload;
  },
  updateUserList: (payload) => {
    chart.userList = payload;
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateSingleUser: (payload) => {
    if (payload) {
      utils.mergeParams({
        user: payload,
      });
    } else {
      utils.removeParam('user');
    }
    chart.singleUser = payload;
  },
};

const preprocessData = (categories, users, addDefaultScore) => {
  categories.name = 'Skills';

  // Find the leaf nodes to add default children array and score of 1
  let categoryLeafNodes = utils.findCategoryLeafNodes(categories);

  categoryLeafNodes.forEach((leaf) => {
    // Add a children array if missing
    if (!leaf.children) {
      leaf.children = [];
    }

    // If a leaf has no children and no size has been added, add one size so it still appears in the sunburst.
    if ((addDefaultScore && !leaf.size) || (leaf.size && leaf.size === 0)) {
      leaf.size = 1;
    }
  });

  console.log({ users });
  users.forEach((user, i) => {
    if (!user.scores) {
      user.scores = [];
    }
    let index = user.scores.length - 1;
    while (index >= 0) {
      const scoreEntry = user.scores[index];
      let category = utils.findCategoryById(categories, scoreEntry.category_id);
      if (category) {
        scoreEntry.category_name = category.name;
      }
      if (scoreEntry.score === 0) {
        user.scores.splice(index, 1);
      }
      index -= 1;
    }
    user.index = i;
  });

  // Add the user data to the categories data
  utils.addScoreDataToStructure(users, categories);
};

const createMainSunburst = (categories) => {
  const sunburstContainer = d3.select('div#sunburst-container');
  sunburstContainer.selectAll('*').remove();

  const sunburst = new SunBurst().init(sunburstContainer, categories);

  sunburst.plot();
  sunburst.enableInteraction(true, (node) => {
    actions.updateCategory(node.data.id);
  });
  return sunburst;
};

const createBreadcrumb = (categories) => {
  const breadcrumbContainer = d3.select('div#breadcrumb-container');
  breadcrumbContainer.selectAll('*').remove();

  const breadcrumb = new Breadcrumb().init(categories);
  breadcrumb.plot(breadcrumbContainer);

  breadcrumb.setClickListener((id) => {
    actions.updateCategory(id);
  });

  return breadcrumb;
};

const createUserList = (users, categoryId) => {
  const userListElement = d3.select('ul#user-list-group');
  userListElement.selectAll('*').remove();

  const summaryTableElement = d3.select('div#leaf-node-summary-table');
  summaryTableElement.selectAll('*').remove();

  const userList = new UserList().init(users);
  userList.plot(userListElement, summaryTableElement, categoryId);
  userList.setClickListener((user) => {
    actions.updateSingleUser(user.email);
  });
  return userList;
};

const createUserTable = (categories) => {
  const userTable = new UserSkillsTable().init(chart.singleUser, categories);
  userTable.plot();
  userTable.setBackClickListener(() => {
    actions.updateSingleUser(null);
  });
  return userTable;
};

const drawSingleUserSunBurst = (personSunburst, ogCategories, user) => {
  let singleCategories = JSON.parse(JSON.stringify(ogCategories));
  let singleUser = [JSON.parse(JSON.stringify(user))];
  preprocessData(singleCategories, [singleUser], false);

  personSunburst.setData(singleCategories, user);
  personSunburst.show();
  personSunburst.autosize();
};

const createSingleUserSunburst = (ogCategories, user) => {
  let singleCategories = JSON.parse(JSON.stringify(ogCategories));
  let singleUser = JSON.parse(JSON.stringify(user));
  preprocessData(singleCategories, [singleUser], false);

  const sunburstContainer = d3.select('div#single-person-sunburst-container');
  sunburstContainer.selectAll('*').remove();

  const personSunBurst = new FullViewSunBurst().init(singleCategories, user);
  personSunBurst.plot(sunburstContainer);
  personSunBurst.autosize();
  return personSunBurst;
};

const useChart = async () => {
  let categories = {};
  let users = [];

  // Get the data
  try {
    categories = await getCategories();
    users = await getUsers();
  } catch (e) {
    return Promise.reject(e);
  }

  const ogCategories = JSON.parse(JSON.stringify(categories));

  preprocessData(categories, users, true);
  const sunburst = createMainSunburst(categories);
  const breadcrumb = createBreadcrumb(categories);

  const userTable = createUserTable(ogCategories);
  let userList = createUserList(users, '');
  let singleUserSunburst;

  watch(
    () => ({
      ...chart,
    }),
    (state) => {
      if (state.category >= 0) {
        sunburst.moveSunburstToCategoryById(state.category);
        breadcrumb.setCategory(state.category);
      }

      if (state.userList.length > 0) {
        let filteredUsers = [];
        let leafNodeScores = [];
        let categoryObject = utils.findCategoryById(categories, state.category);
        let possibleLeafNodes = utils.findCategoryLeafNodes(categoryObject);
        possibleLeafNodes = possibleLeafNodes.map(
          (categoryObject) => categoryObject.name
        );
        state.userList.forEach((user) => {
          let foundScore = user.scores.find(
            (scoreEntry) =>
              possibleLeafNodes.indexOf(scoreEntry.category_name) !== -1
          );
          if (foundScore) {
            filteredUsers.push(user);
            if (possibleLeafNodes.length === 1) {
              leafNodeScores.push(foundScore.score);
            }
          }
        });

        let summaryInfo = {
          heaps: 0,
          reasonable: 0,
          some: 0,
        };

        for (let score of leafNodeScores) {
          switch (score) {
            case 3:
              summaryInfo.heaps++;
              break;
            case 2:
              summaryInfo.reasonable++;
              break;
            case 1:
              summaryInfo.some++;
              break;
            default:
              console.log(`Not meant to get here, ignoring score of ${score}`);
              break;
          }
        }

        // userList = createUserList(users, state.category);

        // Sort user list by skill level if on leaf node
        if (possibleLeafNodes.length === 1) {
          filteredUsers = filteredUsers.sort((a, b) => {
            const aScore = a.scores.find(
              (score) => score.category_id === state.category
            ).score;
            const bScore = b.scores.find(
              (score) => score.category_id === state.category
            ).score;
            return bScore - aScore;
          });

          // Insert dividers between the skill levels
          filteredUsers = filteredUsers.reduce(
            (result, element, index, array) => {
              let curScore = array[index].scores.find(
                (score) => score.category_id === state.category
              ).score;

              if (index === 0) {
                switch (curScore) {
                  case 3:
                    result.push({
                      divider: true,
                      split: `HEAPS (${summaryInfo.heaps})`,
                      index: 'Heaps',
                    });
                    break;
                  case 2:
                    result.push({
                      divider: true,
                      split: `REASONABLE (${summaryInfo.reasonable})`,
                      index: 'Reasonable',
                    });
                    break;
                  case 1:
                    result.push({
                      divider: true,
                      split: `SOME (${summaryInfo.some})`,
                      index: 'Some',
                    });
                    break;
                }
                element.skillLevel = curScore;
                result.push(element);
                return result;
              }

              if (filteredUsers[index + 1] && filteredUsers[index + 1].scores) {
                element.skillLevel = curScore;
                result.push(element);

                let nextScore = array[index + 1].scores.find(
                  (score) => score.category_id === state.category
                ).score;
                if (curScore !== nextScore) {
                  switch (nextScore) {
                    case 3:
                      result.push({
                        divider: true,
                        split: `HEAPS (${summaryInfo.heaps})`,
                        index: 'Heaps',
                        score: 3,
                      });
                      break;
                    case 2:
                      result.push({
                        divider: true,
                        split: `REASONABLE (${summaryInfo.reasonable})`,
                        index: 'Reasonable',
                        score: 2,
                      });
                      break;
                    case 1:
                      result.push({
                        divider: true,
                        split: `SOME (${summaryInfo.some})`,
                        index: 'Some',
                        score: 1,
                      });
                      break;
                  }
                }
              } else if (filteredUsers[index]) {
                element.skillLevel = curScore;
                result.push(element);
              }
              return result;
            },
            []
          );
        }

        userList.setUsers(
          filteredUsers,
          state.category,
          summaryInfo,
          leafNodeScores.length > 0
        );

        userTable.setUser(state.singleUser);
      }

      if (state.singleUser) {
        let foundUser = users.find((user) => user.email === state.singleUser);
        if (foundUser) {
          sunburst.hide();
          breadcrumb.hide();
          if (userList) {
            userList.hide();
          }
          if (!singleUserSunburst) {
            singleUserSunburst = createSingleUserSunburst(
              ogCategories,
              foundUser
            );
          }
          drawSingleUserSunBurst(singleUserSunburst, ogCategories, foundUser);
          userTable.setUser(foundUser);
          userTable.show();
        } else {
          actions.updateSingleUser(null);
        }
      } else {
        if (singleUserSunburst) {
          singleUserSunburst.hide();
        }
        userTable.hide();
        sunburst.show();
        breadcrumb.show();
        if (userList) {
          userList.show();
        }
      }
    }
  );

  let params = utils.getParamsAsObject();

  if (params.hasOwnProperty('category')) {
    try {
      actions.updateCategory(parseInt(params.category));
    } catch (e) {
      console.error(e);
    }
  } else {
    actions.updateCategory(categories.id);
  }

  if (params.hasOwnProperty('user')) {
    actions.updateSingleUser(params.user);
  }

  actions.updateUserList(users);

  useSearch(actions);
};

export default useChart;
