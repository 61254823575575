import * as d3 from 'd3';
import utils from '@/helpers/chart-utils';

const UserList = function () {
  const self = {};
  let rootListElement, listContainer, rootSummaryElement;
  let users = [];
  let listItems;
  let listItemClickListener;
  let leafNodeScores, isLeafNode;

  function listItemClicked(item) {
    if (listItemClickListener) {
      listItemClickListener(item);
    }
  }

  function update(categoryId) {
    listItems = rootListElement.selectAll('.user').data(users, (d) => d.index);

    if (isLeafNode) {
      listItems.sort((a, b) => {
        const aScore = a.divider
          ? a.score
          : a.scores.find((score) => score.category_id === categoryId).score;
        const bScore = b.divider
          ? b.score
          : b.scores.find((score) => score.category_id === categoryId).score;

        return bScore - aScore;
      });
    }

    listItems.exit().transition().duration(400).style('opacity', 0).remove();

    let userEnter = listItems.enter();
    let userItem = userEnter
      .append('li')
      .attr('class', 'user')
      .style('opacity', 0);

    listItems.attr('class', (d) => {
      if (isLeafNode && d.skillLevel) {
        return 'user ' + `skill-level-${d.skillLevel}`;
      } else {
        return 'user';
      }
    });

    if (userItem.data() && userItem.data()[0] && !userItem.data()[0].divider) {
      userItem.style('cursor', 'pointer').on('click', listItemClicked);
    }

    userItem.transition().duration(400).style('opacity', 1);
    userItem.html((d) => {
      if (d.divider) {
        return `<p class="leaf-node-summary-entry-divider" style="background-color: ${utils.getColorForScore(
          d.index
        )}">${d.split}</p>`;
      } else {
        return `${d.firstName} ${d.lastName}`;
      }
    });

    let totalSelection = rootSummaryElement.selectAll('.total');
    totalSelection.remove();
    let totalUsersWithoutDividers =
      users.length - users.filter((user) => user.divider).length;
    rootSummaryElement
      .append('p')
      .attr('class', 'total')
      .text(`(${totalUsersWithoutDividers})`);
  }

  self.init = function (userData) {
    users = userData;
    leafNodeScores = [];
    return self;
  };

  self.plot = function (userListElement, summaryTableElement, categoryId) {
    rootListElement = userListElement;
    rootListElement.selectAll('*').remove();

    rootSummaryElement = summaryTableElement;
    rootSummaryElement.selectAll('*').remove();

    listContainer = d3.select('div#skills-matrix-user-list-container');
    update(categoryId);
  };

  self.setUsers = function (
    userData,
    categoryId,
    leafNodeScoreData,
    isOnLeafNode
  ) {
    users = userData;
    leafNodeScores = leafNodeScoreData;
    isLeafNode = isOnLeafNode;
    update(categoryId);
  };

  self.setClickListener = function (listener, categoryId) {
    listItemClickListener = listener;
    update(categoryId);
  };

  self.show = function () {
    listContainer.style('display', null);
  };

  self.hide = function () {
    listContainer.style('display', 'none');
  };

  return self;
};

export default UserList;
