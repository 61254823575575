import * as d3 from 'd3';
import utils from '@/helpers/chart-utils';

const UserSkillsTable = function () {
  const self = {};
  let selectedUser, categoriesData, tableContainer;
  let backClickListener;
  let colorFunc;

  const nestByScore = d3
    .nest()
    .key((d) => d.score)
    .sortKeys(d3.descending);

  function lookUpCategoryName(categoryId) {
    let found = utils.findCategoryById(categoriesData, categoryId);
    if (!!found) {
      return found.name;
    } else {
      return '';
    }
  }

  function findColor(categoryId) {
    let ancestors = utils.findAncestorsById(categoryId, categoriesData);
    let name = ancestors[2] && ancestors[2].name;
    let col = colorFunc(name);
    return shadeColor(col, 0.4);
  }

  /**
   * Shifts a color's intensity to the given percentage
   *
   * @param color a color string
   * @param percent the percentage shift to apply
   * @returns {string} the shifted color
   */
  function shadeColor(color, percent) {
    const f = color.split(','),
      t = percent < 0 ? 0 : 255,
      p = percent < 0 ? percent * -1 : percent,
      R = parseInt(f[0].slice(4)),
      G = parseInt(f[1]),
      B = parseInt(f[2]);
    return (
      'rgb(' +
      (Math.round((t - R) * p) + R) +
      ',' +
      (Math.round((t - G) * p) + G) +
      ',' +
      (Math.round((t - B) * p) + B) +
      ')'
    );
  }

  function drawSelectedUserTable() {
    for (let i = 1; i < 4; i++) {
      d3.select('td#user-skill-category-cell-' + i)
        .selectAll('*')
        .remove();
    }

    if (
      selectedUser &&
      selectedUser.hasOwnProperty('email') &&
      selectedUser.hasOwnProperty('scores') &&
      selectedUser.scores.length > 0
    ) {
      d3.select('h3#selected-user-table-heading').text(
        `${selectedUser.firstName} ${selectedUser.lastName}`
      );
      d3.select('h5#selected-user-table-sub-heading').selectAll('*').remove();
      d3.select('h5#selected-user-table-sub-heading')
        .append('a')
        .attr('href', `mailto:${selectedUser.email}`)
        .text(`${selectedUser.email}`);

      for (let skillLevel of nestByScore.entries(selectedUser.scores)) {
        let skillsCell = d3.select(
          'td#user-skill-category-cell-' + skillLevel.key
        );
        skillsCell.selectAll('*').remove();

        // Add specific user skills
        let skills = skillsCell
          .selectAll('.user-skill')
          .data(skillLevel.values);

        let skillEnter = skills.enter();

        skillEnter
          .append('a')
          .attr(
            'class',
            'user-skill skills-matrix-badge skills-matrix-badge-pill'
          )
          .style('background-color', (d) => findColor(d.category_id))
          .style('fill-opacity', 0.8)
          .text((d) => {
            return lookUpCategoryName(d.category_id);
          });
      }
    } else if (
      selectedUser &&
      selectedUser.hasOwnProperty('scores') &&
      selectedUser.scores.length === 0
    ) {
      d3.select('h3#selected-user-table-heading').text(
        `${selectedUser.firstName} ${selectedUser.lastName} hasn't entered any skills yet...`
      );
    }
  }

  self.init = function (user, categories) {
    selectedUser = user;
    categoriesData = categories;
    colorFunc = utils.createColorFunction(categories);
    return self;
  };

  self.plot = function () {
    tableContainer = d3.select('div#skills-matrix-table-container');
    drawSelectedUserTable();
  };

  self.setBackClickListener = function (listener) {
    backClickListener = listener;
    d3.select('button#selected-user-table-back-button').on(
      'click',
      backClickListener
    );
  };

  self.setUser = function (user) {
    selectedUser = user;
    if (user && user.hasOwnProperty('email')) {
      let tempCats = JSON.parse(JSON.stringify(categoriesData));
      tempCats.name = 'Skills';

      let categoryLeafNodes = utils.findCategoryLeafNodes(tempCats);

      categoryLeafNodes.forEach((leaf) => {
        // Add a children array if missing
        if (!leaf.children) {
          leaf.children = [];
        }
      });

      utils.addScoreDataToStructure([user], tempCats);
      colorFunc = utils.createColorFunction(tempCats);
    }
    drawSelectedUserTable();
  };

  self.show = function () {
    tableContainer.style('display', null);
  };

  self.hide = function () {
    tableContainer.style('display', 'none');
  };

  return self;
};

export default UserSkillsTable;
