import * as d3 from 'd3';
import utils from '@/helpers/chart-utils';

const FullViewSunBurst = function () {
    const self = {};
    let sunburstData, sunburstElement, selectedUser;
    let width = 976, radius = 500;
    let svg, colorFunc;
    let maxValue;
    let colorInterpolation = d3.interpolateRainbow;

    function partitonData(data) {
        return d3.partition()
            .size([2 * Math.PI, radius])
            (d3.hierarchy(data)
                .sum(d => d.size)
                .sort((a, b) => b.value - a.value))
    }

    function autoBox() {
        const {x, y, width, height} = this.getBBox();
        return [x, y, width, height];
    }

    function color(d) {
        return d3.scaleOrdinal(d3.quantize(d3.interpolateRainbow, sunburstData.children.length + 1))(d);
    }

    function format(d) {
        return d3.format(",d")(d);
    }

    function shouldFlip(d) {
        return (
            // Start of arc is in bottom half of circle
            d.x0 > (Math.PI / 2) && d.x0 < (Math.PI * 1.6) &&
            // End of arc is just inside 4th quadrant,
            (d.x1 < (Math.PI * 1.6) ||
                // or is slightly further inside and in the center two rings
                (d.x1 < (Math.PI * 1.7) && d.y0 < 200)));
    }

    function arc(d) {
        if (shouldFlip(d)) {
            return d3.arc()
                .startAngle(d => d.x1)
                .endAngle(d => d.x0)
                .padAngle(d => Math.min((d.x1 - d.x0) / 2, 0.005))
                .padRadius(radius / 2)
                .innerRadius(d => d.y0)
                .outerRadius(d => d.y1 - 1)(d);
        } else {
            return d3.arc()
                .startAngle(d => d.x0)
                .endAngle(d => d.x1)
                .padAngle(d => Math.min((d.x1 - d.x0) / 2, 0.005))
                .padRadius(radius / 2)
                .innerRadius(d => d.y0)
                .outerRadius(d => d.y1 - 1)(d);
        }
    }


    self.init = function (data, user) {
        selectedUser = user;
        colorFunc = utils.createColorFunction(data);
        sunburstData = partitonData(data);
        maxValue = sunburstData.value;
        return self;
    };

    self.plot = function (element) {
        sunburstElement = element;
        element.selectAll('*').remove();
        svg = element.append("svg")
            .style("max-height", "100%")
            .style("width", "auto")
            .style("font-size", `${7.5 / sunburstData.height}em`)
            // .style("font", "10px sans-serif")
            .style("margin", "5px");

        let g = svg.selectAll(".group")
            .data(sunburstData.descendants().filter(d => d.depth))
            .enter().append("g")
            .attr("class", "group");

        g.append("path")
            .attr("fill", d => {
                // let col = colorFunc(d.data.name);
                // return col;
                let shadePercent = ((d.value / maxValue));
                shadePercent = shadePercent>.4?.4:shadePercent;
                while (d.depth > 2) d = d.parent;
                return utils.shadeColor(colorFunc(d.data.name), shadePercent)
                // return colorFunc(d.data.name)
            })
            .attr("fill-opacity", 0.8)
            .attr('id', d => `group-${d.data.name}-${d.data.id}`)
            .attr("d", arc)
            .append("title")
            .text(d => `${d.ancestors().map(d => d.data.name).reverse().slice(1).join("/")}`);

        g.append("text")
            .attr("x", 6)
            .attr("dy", d => {
                if (shouldFlip(d)) {
                    return -10;
                } else {
                    return 30;
                }
            })
            .filter(d => {
                return d.value;
            })
            .append("textPath")
            .attr("xlink:href", d => `#group-${d.data.name}-${d.data.id}`)
            .text(d => {
                if (((d.x1 - d.x0) * d.depth) / (d.data.name.length + 5) > 0.12) {
                    return `${d.data.name} (${d.value})`;
                } else if ((((d.x1 - d.x0) * d.depth) / (d.data.name.length + 5) <= 0.06)) {
                    return '';
                } else {
                    let letters = `${d.data.name} (${d.value})`.split('');
                    return letters.slice(0, Math.ceil(((d.x1 - d.x0) * d.depth) / 0.2)).join('') + '...';
                }
            })
            .append("title")
            .text(d => `${d.ancestors().map(d => d.data.name).reverse().slice(1).join("/")}`);

        let center = svg.append('g');
        center.append("text")
            .style("text-anchor", "middle")
            .style("vertical-align", "middle")
            .attr("dy", "0.5em")
            .text(() => {
                if (selectedUser) {
                    if (selectedUser.scores.length === 0) {
                        return ``
                    }
                    return `${selectedUser.firstName[0]}. ${selectedUser.lastName[0]}.`
                } else {
                    return "";
                }
            });

        return svg.node();
    };

    self.autosize = function () {
        svg.attr("viewBox", autoBox);
    };

    self.setData = function (categories, user) {
        selectedUser = user;
        colorFunc = utils.createColorFunction(categories);
        sunburstData = partitonData(categories);
        maxValue = sunburstData.value;
        self.plot(sunburstElement);
    };

    self.hide = function () {
        sunburstElement.style("display", "none");
    };

    self.show = function () {
        sunburstElement.style("display", null);
    };

    return self;
};

export default FullViewSunBurst;
