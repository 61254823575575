import AutoComplete from '@tarekraafat/autocomplete.js';
import utils from '@/helpers/chart-utils';
import { getCategories, getUsers } from '@/services/DataService';

const getSourceData = async () => {
  const users = await getUsers();
  const cats = await getCategories();

  let catNodes = utils.findAllCategoryNodes(cats);

  let autocompletables = [];

  users.forEach((user) => {
    user.autocomplete = `${user.firstName} ${user.lastName}`;
    user.type = 'user';
    autocompletables.push(user);
  });

  catNodes.forEach((cat) => {
    autocompletables.push({
      autocomplete: cat.name,
      id: cat.id,
      type: 'category',
    });
  });

  return autocompletables;
};

const useSearch = (actions) =>
  new AutoComplete({
    data: {
      src: getSourceData,
      key: ['autocomplete'],
      cache: true,
    },
    selector: '#autocomplete',
    maxResults: 25,
    resultsList: {
      render: true,
      destination: document.querySelector('#autocomplete'),
      position: 'afterend',
      element: 'ul',
    },
    noResults: () => {
      const result = document.createElement('li');
      result.setAttribute('class', 'no-result form-control');
      result.innerHTML = 'No matching results';
      document.querySelector('#autoComplete_results_list').appendChild(result);
    },
    onSelection: (feedback) => {
      document.querySelector('#autocomplete').value = '';
      // update state here
      if (feedback.selection.value.type === 'user') {
        actions.updateSingleUser(feedback.selection.value.email);
      } else if (feedback.selection.value.type === 'category') {
        actions.updateCategory(feedback.selection.value.id);
      }
    },
  });

export default useSearch;
