import * as d3 from 'd3';
import utils from '@/helpers/chart-utils';

const Breadcrumb = function() {
    const self = {};
    let categoryData;
    let breadcrumbElement;
    let crumbs = [];
    let crumbClickListener;

    function breadcrumbClicked(breadcrumb) {
        if (crumbClickListener) {
            crumbClickListener(breadcrumb.data.id);
        }
    }

    function update() {
        let breadcrumbs = breadcrumbElement.selectAll('.crumb').data(crumbs);

        breadcrumbs.exit()
            .transition().duration(750).style("opacity", 0)
            .remove();

        let crumbEnter = breadcrumbs.enter();

        let crumbItem = crumbEnter.append('span')
            .attr('class', 'crumb')
            .style("opacity", 0)
            .style("cursor", "pointer")
            .merge(breadcrumbs)
            .text(d => d.data.name)
            .on("click", breadcrumbClicked);

        crumbItem.transition().duration(750).style("opacity", 1);
    }

    function getCrumbs(id) {
        let newCrumbs = [];
        let found = utils.findLeafNodeOfPartitionedDataById(categoryData, id);
        if (found) {
            newCrumbs = found.ancestors().reverse();
        }
        return newCrumbs;
    }

    self.init = function (categories) {
        categoryData = d3.hierarchy(JSON.parse(JSON.stringify(categories)));
        return self;
    };

    self.plot = function (element) {
        breadcrumbElement = element;
        update();
    };

    self.setCategory = function (categoryId) {
        crumbs = getCrumbs(categoryId);
        update();
    };

    self.setClickListener = function (listener) {
        crumbClickListener = listener;
        update();
    };

    self.hide = function() {
        breadcrumbElement.style('display', 'none');
    };

    self.show = function() {
        breadcrumbElement.style('display', null);
    };

    return self;
};

export default Breadcrumb;
