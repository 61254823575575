import * as d3 from 'd3';

const colorInterpolator = d3.interpolateRainbow;

function findCategoryById(_node, id) {
  let found = null;
  function recurse(node) {
    if (node.hasOwnProperty('id') && node.id === id) {
      found = node;
    } else if (node.children) {
      node.children.forEach(recurse);
    }
  }

  recurse(_node);
  return found;
}

function findAncestorsById(id, tree = null) {
  function loop(path, node) {
    if (node.hasOwnProperty('id') && node.id === id) {
      path.push(node);
      return path;
    } else {
      return node.children
        ? node.children.reduce(
            (acc, child) => acc.concat(loop([...path, node], child)),
            []
          )
        : [];
    }
  }

  return loop([], tree);
}

function findCategoryByName(_node, name) {
  let found = null;

  function recurse(node) {
    if (node.name && node.name === name) {
      found = node;
    } else if (node.children) {
      node.children.forEach(recurse);
    }
  }

  recurse(_node);
  return found;
}

function findNode(data, name) {
  let found = null;

  function recurse(node) {
    if (node.data && node.data.name === name) {
      found = node;
    } else if (node.children) {
      node.children.forEach(recurse);
    }
  }

  recurse(data);
  return found;
}

function findLeafNodeOfPartitionedDataById(_node, id) {
  let found = null;

  function recurse(node) {
    if (node.data && node.data.id === id) {
      found = node;
    } else if (node.children) {
      node.children.forEach(recurse);
    }
  }

  recurse(_node);
  return found;
}

function getScoreNameFromId(scoreId) {
  if (!scoreId) {
    return '';
  }
  switch (scoreId) {
    case 1:
      return 'Some';
    case 2:
      return 'Reasonable';
    case 3:
      return 'Heaps';
  }
  console.log(`no case ${JSON.stringify(scoreId)}`);
}

function findNodeOfPartitionedDataByName(treeStructure, name) {
  let found = null;

  function recurse(node) {
    if (!found) {
      if (node.data && node.data.name === name) {
        found = node;
      } else if (node.children) {
        node.children.forEach(recurse);
      }
    }
  }

  recurse(treeStructure);
  return found;
}

function partition(data) {
  const root = d3
    .hierarchy(data)
    .sum((d) => {
      return d.size;
    })
    .eachAfter(function (node) {
      var sum = node.data.score || 0,
        children = node.children,
        i = children && children.length;
      while (--i >= 0) sum += children[i].score;
      node.score = sum;
    })
    .sort((a, b) => b.value - a.value);
  return d3.partition().size([2 * Math.PI, root.height + 1])(root);
}

function getScoreFromUser(scores, categoryId) {
  if (!scores || categoryId == null || categoryId.length == 0) {
    console.log(`something is null ${categoryId}`);
    return null;
  }
  let score = scores.filter((score) => score.category_id === categoryId);

  const scoreId = score[0] ? score[0].score : {};
  var scoreName = getScoreNameFromId(scoreId);
  return scoreName;
}

function addScoreDataToStructure(users, categories) {
  let userLeafNodes = [];

  for (let key in users) {
    let user = users[key];
    if (user.scores && user.scores.length !== 0) {
      let entries = user.scores;
      for (let entry of entries) {
        entry.email = user.email;
        entry.userIndex = user.index;
        userLeafNodes.push(entry);
      }
    }
  }

  for (let i = 0; i < userLeafNodes.length; i++) {
    let leafNode = findCategoryById(categories, userLeafNodes[i].category_id);
    if (leafNode) {
      if (leafNode.score) {
        leafNode.score = leafNode.score + userLeafNodes[i].score;
      } else {
        leafNode.score = userLeafNodes[i].score;
      }
      if (leafNode.size) {
        leafNode.size = leafNode.size + userLeafNodes[i].score;
      } else {
        leafNode.size = userLeafNodes[i].score;
      }
    }
  }
}

function findCategoryLeafNodes(tree) {
  let categoryLeafNodes = [];

  function recurse(tree) {
    if (tree.children && tree.children.length > 0) {
      for (let child of tree.children) {
        recurse(child);
      }
    } else {
      categoryLeafNodes.push(tree);
    }
  }

  recurse(tree);
  return categoryLeafNodes;
}

function findAllCategoryNodes(tree) {
  let nodes = [];

  function recurse(tree) {
    if (tree.children && tree.children.length > 0) {
      for (let child of tree.children) {
        if (child.children) nodes.push(child);
        recurse(child);
      }
    } else {
      nodes.push(tree);
    }
  }

  recurse(tree);
  return nodes;
}

function getColorByIdOnCategories(id, categories) {
  let topLevelCat = findAncestorsById(id, categories)[1];
  return d3
    .scaleOrdinal()
    .domain(['Placeholder'].concat(categories.children.map((cat) => cat.name)))
    .range(d3.quantize(d3.interpolateRainbow, categories.children.length + 1))(
    topLevelCat.name
  );
}

function getColorForScore(score) {
  if (!score) {
    return '';
  }
  switch (score.toLowerCase()) {
    case 'some':
      return '#a7a9ac';
    case 'reasonable':
      return '#218e8a';
    case 'heaps':
      return '#d81f4a';
  }
  console.log(`no case ${JSON.stringify(scoreId)}`);
}

function getFormattedUserWithScore(user, categoryId) {
  var score = user.skillLevel;
  var color = getColorForScore(score);
  // score = score ==='Heaps' ? '<b>' + score +'</b>' : score;
  var output = `<font color="${color}">${user.firstName} ${user.lastName}</font> `;
  return output;
}

function createColorFunction(categories) {
  let hierarchy = d3.hierarchy(categories).sum((d) => d.size);
  let nodes = [];
  hierarchy.eachBefore((node) => {
    if (node.value && node.value > 0) nodes.push(node.data.name);
  });
  // return d3.scaleOrdinal()
  //     .domain(nodes)
  //     .range(d3.quantize(d3.interpolateRgb(clearPointColors), nodes.length + 10))
  return d3
    .scaleOrdinal()
    .domain(nodes)
    .range(d3.quantize(colorInterpolator, nodes.length + 10));
}

/**
 * Shifts a color's intensity to the given percentage
 *
 * @param color a color string
 * @param percent the percentage shift to apply
 * @returns {string} the shifted color
 */
function shadeColor(color, percent) {
  const f = color.split(','),
    t = percent < 0 ? 0 : 255,
    p = percent < 0 ? percent * -1 : percent,
    R = parseInt(f[0].slice(4)),
    G = parseInt(f[1]),
    B = parseInt(f[2]);
  return (
    'rgb(' +
    (Math.round((t - R) * p) + R) +
    ',' +
    (Math.round((t - G) * p) + G) +
    ',' +
    (Math.round((t - B) * p) + B) +
    ')'
  );
}

/**
 * This is straight from d3 website, no clue how it works, probably why it doesn't work very well...
 * @param text
 * @param width
 */
function buggyWrap(text, width) {
  text.each(function () {
    let text = d3.select(this),
      words = text.text().split(/\s+/).reverse(),
      word,
      line = [],
      lineNumber = 0,
      lineHeight = 20, // ems
      y = text.attr('y'),
      dy = parseFloat(text.attr('dy')),
      opacity = parseFloat(text.style('opacity')),
      tspan = text
        .text(null)
        .append('tspan')
        .attr('x', 0)
        .attr('y', y)
        .attr('dy', dy + 'px')
        .style('opacity', opacity);
    while ((word = words.pop())) {
      line.push(word);
      tspan.text(line.join(' '));
      if (tspan.node().getComputedTextLength() > width) {
        line.pop();
        tspan.text(line.join(' '));
        line = [word];
        tspan = text
          .append('tspan')
          .attr('x', 0)
          .attr('y', y)
          .attr('dy', lineHeight + 'px')
          .text(word);
      }
    }
  });
}

function render(method) {
  d3.select(this).call(method);
}

function getParams() {
  return new URLSearchParams(window.location.search);
}

function getParamsAsObject() {
  let params = new URLSearchParams(window.location.search);

  function paramsToObject(entries) {
    let result = {};
    for (let entry of entries) {
      // each 'entry' is a [key, value] tupple
      const [key, value] = entry;
      result[key] = value;
    }
    return result;
  }

  return paramsToObject(params.entries());
}

function setParams(params) {
  if (history.replaceState) {
    let newQuery = new URLSearchParams(params).toString();
    let newUrl_ = `${window.location.protocol}//${window.location.host}${window.location.pathname}?${newQuery}`;
    window.history.replaceState({ path: newUrl_ }, '', newUrl_);
  }
}

function mergeParams(params) {
  let oldParams = getParamsAsObject();
  setParams({
    ...oldParams,
    ...params,
  });
}

function removeParam(key) {
  let oldParams = getParamsAsObject();
  delete oldParams[key];
  setParams({
    ...oldParams,
  });
}

const nestByScore = d3
  .nest()
  .key((d) => d.score)
  .sortKeys(d3.descending);

export default {
  addScoreDataToStructure,
  findCategoryById,
  findAncestorsById,
  findCategoryByName,
  findCategoryLeafNodes,
  findAllCategoryNodes,
  findLeafNodeOfPartitionedDataById,
  findNodeOfPartitionedDataByName,
  findNode,
  partition,
  render,
  nestByScore,
  buggyWrap,
  getParamsAsObject,
  mergeParams,
  removeParam,
  createColorFunction,
  shadeColor,
  getScoreFromUser,
  getScoreNameFromId,
  getColorForScore,
  getFormattedUserWithScore,
};
